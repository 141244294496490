import React from "react";
import Layout from "../components/layout";
import Breadcrumb from "../components/breadcrumb";
import GlobalConstants from "../helpers/constants";
import Axios from "axios";
import { Grid, Typography } from "@material-ui/core";
import { GetComponentByName } from "../helpers/query-data";
import { GetSimplePathObj } from "../helpers/data-formatting";
import { GQLQuery, GQLRichTextContent, GQLSingleLineContent } from "../types";
import { CrystallizeBaseURL } from "../helpers/constants";

const MyQuery = `
  {
    catalogue(path: "/articles/about-us") {
      ...AU_item
    }
  }

  fragment AU_item on Item {
    name
    components {
      ...AU_component
    }
  }

  fragment AU_component on Component {
    name
    content {
      ...AU_singleLine
      ...AU_richText
    }
  }

  fragment AU_singleLine on SingleLineContent {
    text
  }

  fragment AU_richText on RichTextContent {
    html
  }
`;

const About = (): JSX.Element => {
  const [QueryResult, SetQueryResult] = React.useState({} as GQLQuery);
  const PathObj = GetSimplePathObj(GlobalConstants.AboutUs);

  Axios.post(CrystallizeBaseURL, { query: MyQuery }).then((response) => {
    SetQueryResult(response.data.data as GQLQuery);
  });

  const ContentCompContent = GetComponentByName(
    QueryResult.catalogue?.components ?? [],
    GlobalConstants.Content
  )?.content as GQLRichTextContent;
  const TitleCompContent = GetComponentByName(
    QueryResult.catalogue?.components ?? [],
    GlobalConstants.Title
  )?.content as GQLSingleLineContent;

  return (
    <Layout currentPage="/about">
      <Breadcrumb pathObj={PathObj} />
      <Grid item xs={12}>
        <Typography variant="h6">{TitleCompContent?.text}</Typography>
        {ContentCompContent?.html.map((node, index) => (
          <div
            key={index}
            className="MuiTypography-root MuiTypography-body2"
            dangerouslySetInnerHTML={{ __html: node }}
          />
        ))}
      </Grid>
    </Layout>
  );
};

export default About;
